import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Hero } from "../components/hero"
import PageCTA from "../components/pageCTA"

export const query = graphql`
  query ReviewsPageQuery {
    file(name: { eq: "bostonian-electric-electrician-working" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
const ReviewsPage = ({ data }) => {
  const banner = data.file.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Reviews" />
      <Hero
        image={banner}
        header="Bostonian Electric Reviews"
        alt="Electrician working on wires"
      />
      <section className="container my-6 mx-auto px-4 font-sans">
        <h2>Real Customers, Real Reviews</h2>
        <p>
          Check out what our customers have to say about Bostonian Electric.
        </p>
        <blockquote className="bg-gray-100 p-4">
          "Bostonian Electric did great work on my house. They arrived on time
          and finished when they said they would."
          <br /> - Phil M.
          <br />
          Braintree, MA
        </blockquote>
      </section>
      <PageCTA
        h2="Ready to learn more?"
        buttonText="Get a quote"
        destination="/contact/"
      />
    </Layout>
  )
}
export default ReviewsPage
