import * as React from "react"
import { Link, navigate } from "gatsby"

const PageCTA = ({ h2, children, buttonText, destination }) => (
  <section className="page-cta container my-6 sm:my-12 mx-auto px-4 font-sans">
    {/* <div className="flex flex-col"> */}
    <hr className="my-6" />
    <div className="flex flex-col md:flex-row space-y-4">
      <div className="flex-grow space-y-4">
        <h2 className="text-4xl">{h2}</h2>
        {children ? (
          children
        ) : (
          <p>
            Please{" "}
            <Link
              className="text-blue-700 hover:underline"
              to="/contact/"
              title="contact"
            >
              contact us
            </Link>{" "}
            for a quote.
          </p>
        )}
      </div>
      <div className="flex-grow">
        <div className="flex justify-center">
          <button
            className="text-center px-6 py-4 rounded-full uppercase text-gray-900 bg-yellow-400 hover:bg-yellow-500 hover:text-white"
            // className={`text-center px-6 py-4 rounded-full uppercase ${highlightFirstButton ? "text-gray-900 bg-yellow-400 hover:bg-yellow-500 hover:text-white" : "text-yellow-400 border border-yellow-400 bg-transparent hover:bg-yellow-400 hover:text-gray-900"}`}
            onClick={() => {
              navigate(`${destination}`)
            }}
            title={buttonText}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  </section>
)

export default PageCTA
